*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inheerit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e8e8e8;;
}

a,
li {
  text-decoration: none;
  list-style: none;
}

.Navigation {
  width: 100%;
  display: flex;
  height: 6.4rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fdfdfd;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 2.3rem;
  position: fixed;
  z-index: 10000;
}

.Navigation nav h1 {
  font-size: 4rem;
  font-weight: 300;
  display: flex;
}

.Navigation nav h1 span {
  background-color: rgb(45, 48, 71);
  font-weight: 500;
  color: #fff;
  border-radius: 0.4rem;
  display: flex;
  width: 4rem;
  text-align: center;
  justify-content: center;
}

/* Media Query for screens with a maximum width of 576px */
@media (max-width: 576px) {
  .Navigation nav h1 {
    font-size: 2.6rem;
  } 
}