.Skill {
  width: 95%;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.Description {
  width: 50%;
  height: 80%;
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

.Titles {
  width: 100%;  
  text-transform: full-width;
  font-size: 2.4rem;  
}

.Details {
  font-size: 1.6rem !important;
  margin-bottom: 0.4rem !important;
  font-weight: initial !important;
  text-align: left !important;
}

.Staks {
  color: #cc9100;
  width: 100%;
}

.Contain {
  width: 100%;
  height: 80%;
  position: relative;
  justify-content: center;
  align-items: unset;  
}

.Sub-Menus {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.Sub-Menus li a {
  padding: 1rem;
  border: none;
  color: #fff;
  background-color: rgb(45, 48, 71);
  border-radius: 0.8rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

@media (max-width: 788px) {
  .Contain {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: static;
    text-align: center;
    align-items: center;
  }
  .Titles {
    font-size: medium;
  }

  .Description {
    width: 80%;
  }

  .Details {
    font-size: 1.2rem !important;
    font-weight: lighter;
    text-align: center !important;
  }

}

@media screen and (max-width: 341px) {
  .Titles {
    font-size: 1.4rem;
  }
  .Sub-Menus {
    align-items: center;
    height: 10vh;
  }
}
