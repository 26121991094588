.Contact-form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 60%;
        gap: 2rem;
        margin-bottom: 2.6rem;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        padding: 2rem;
}
label {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        font-weight: lighter;
        font-size: 1.4rem;
}

textarea,
input {
        border: none;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        padding: 0.2rem;
}

input {
        height: 3rem;
}

.Msg {
        height: 14rem;
}

.Contact-form button {
        width: 60%;
        border: none;
        padding: 1.6rem;
        color: #fff;
        background-color: rgb(45, 48, 71);
        border-radius: 0.8rem;
        cursor: pointer;
        align-self: center;
}