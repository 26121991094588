.image-slider {
  border: 0.2rem solid #e0e0e4;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 48%;
  align-content: center;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.slider-image {
  width: 100%;
  height: 30rem;
}

.images {
  width: 100%;
  height: 30rem;
}

@media (max-width: 768px) {
  .image-slider {
    width: 92%;
  }
}
