.Banner {
  width: 100%;
  border-radius: 2rem;
  padding: 1rem;
  justify-content: space-between;
}

.Socials {
  right: 0;
}

.SubLink {
  font-size: 2.2rem;
  border-radius: 0.9rem;
  cursor: pointer;
  color: rgb(45, 48, 71);
}

.Banner__img {
  display: none;
}

.Banner__text h3 {
  font-size: 2.4rem;
}

@media screen and (max-width: 838px) {
  .Banner {
    flex-direction: column;
    align-items: center;
  }
  .Banner__data {
    width: 100%;
  }
  .Socials {
    flex-direction: row;
  }
  .SubLink {
    font-size: 1.8rem;
  }
  .Banner__img {
    display: block;
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
  }
  .Banner__text h3 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 571px) {
  .Banner__data {
    text-align: center;
    flex-direction: column;
  }
  .Banner__text h3 {
    font-size: 1.5rem;
  }
}
