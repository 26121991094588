@media screen and (min-width: 789px) {
        .Navigation__two {
                display: none;
                content: '';
        }
}
@media screen and (max-width: 788px) {
        .Navigation {
                display: none;
                content: '';
        }
        .Navigation__two {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                padding: 1rem;
                background-color: #fff;
                z-index: 1000;
                top: 0;
                position: fixed !important;
        }
        .NavigationOpen {
                position: relative;
        }
        .ContainerClose {
                position: absolute;
                animation: slide-right .2s ease-in-out;
                background-color: #ffffff;
                width: 30%;
                top: 0;
                left: 0;
                height: 100vh;
                z-index: 100000;
                color: #343a40;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        }
        @keyframes slide-right {
                from {
                  margin-left: -100%;
                }
                to {
                  margin-left: 0%;
                }
        }
        .NameClose {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-right: 4rem;
        }
        .NameOpen {
                margin-right: 10rem;
        }
        .MenuItemsOpen {
                display: none;
                content: '';
        }
        .MenuItemsClose {
                display: flex;
                position: absolute;
                flex-direction: column;
        }
        .NavigationClose {
                width: 100%;
        }
        .Btn {
                color: #2d3047;
                border: none;
                border-radius: .9rem;
                cursor: pointer;
                font-size: 3.4rem;
                font-weight: 600;
                background-color: #fff;
        }
        .BtnClose{
                position: absolute;
                right: 0 ;
                margin: 1rem;
                padding: 0.6rem;
                align-items: center;
                display: flex;
                justify-content: center;
                border-radius: 50%;
                font-size: 1.2rem;
                width: fit-content !important;
                color: #343a40;
                align-self: center;
        }
        .MenuItemsClose {
                position: absolute;
                top: 30%;
                transform: translateY(-30%);
                width: 100%;
        }
        .MenuItemsClose > li > a {
                display: flex;
                gap: 1.5rem;
                font-size: 1.6rem;
                padding: 1rem;
                border-bottom: 1px solid black;
                align-items: center;
                justify-content: flex-start;
                color: #343a40;
        }
        .MenuItemsClose > li > a:hover{
                background-color: #343a40;
                color: #fff;
                cursor: pointer;
        }
        .ContainerOpen > p {
                display: none;
                content: '';
        }
        .ContainerClose > p {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                padding-bottom: 2rem;
                font-weight: lighter;
        }
}

@media screen and (max-width: 576px) {
        .NameOpen > h1 {
                font-size: 1.8rem !important;
                width: 7rem !important;
        }
        .Btn {
                font-size: 1.7rem;
        }
        .NameClose span {
                width: 3rem !important;
        }
        .ContainerClose {
                width: 50%;
        }
}

