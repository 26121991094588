.Container-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: inherit;
}
.Container {
  width: 100%;
  display: flex;
  gap: 2rem;
  position: relative;
  top: 7.2rem;
}
.Container-main .Container .Profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
}

.Container-main .Container .Profile-Info {
  position: absolute;
  right: 0;
  gap: 2rem;
  margin: 0 4rem 0 0;
  width: 80%;
}

.Container-main .Container .Profile-Navigation {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 12%;
  background-color: #fff;
  position: fixed;
  z-index: 100;
  color: #343a40;
  left: 0rem;
  margin-left: 1.5rem;
}

.Container-main .Container .User-details {
  margin: 1rem;
}

.Container-main .Container .User-details,
.Container-main .Container .Menu {
  width: 100%;
  gap: 0.5rem;
  position: relative;
}

.Container-main .Container .User-details .User-logo {
  width: 100%;
  border-radius: 50%;
  position: relative;
}

.Container-main .Container .User-details span {
  font-size: 2rem;
  font-weight: 600;
  color: rgb(45, 48, 71);
}

.Container-main .Container .User-details h2 {
  font-size: 1.6rem;
  font-weight: 300;
}
.Container-main .Container .Menu {
  font-size: 1.6rem;
  font-weight: 300;
  gap: 0 !important;
}

.Container-main .Container .Menu li {
  width: 80%;
  border-bottom: 0.06rem solid rgba(50, 50, 93, 0.25);
  height: 3rem;
  display: flex;
  align-items: center;
}

.Container-main .Container .Menu li:hover,
.Container-main .Container .Menu li a:hover {
  background-color: #343a40;
  color: #fff;
  cursor: pointer;
}

.Container-main .Container .Menu li a {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  gap: 1rem;
  font-size: 1.6rem;
  justify-content: flex-start;
  color: #343a40;
  margin-left: 0.8rem;
}

.Container-main .Container p {
  font-size: 1.4rem;
  font-weight: lighter;
  margin-bottom: 0.4rem;
  padding: 1rem;
  text-align: center;
}

.fCol {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}
.fRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.Section {
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: relative;
  border-radius: 2rem;
  justify-content: center;
  background-color: #fff;
  padding: 2rem;
}

.Headers {
  width: 95%;
  height: 1rem;
  align-items: center;
  padding: 2rem;
  color: rgb(45, 48, 71);
}

.Contents {
  width: 95%;
  color: #343a40;
  font-weight: 400;
  margin-bottom: 3rem;
}

.Logo-form {
  background-color: rgb(45, 48, 71);
  font-weight: 500;
  color: #fff;
  border-radius: 0.4rem;
  display: flex;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  padding: 0.4rem;
}

.Scroll-top {
  position: fixed;
  z-index: 200000;
  right: 3rem;
  bottom: 3rem;
  padding: 1rem;
  border: none;
  color: #fff;
  background-color: rgb(45, 48, 71);
  border-radius: 0.8rem;
  cursor: pointer;
}

.Cv-download {
  position: fixed;
  z-index: 200000;
  top: 43%;
  right: -4rem;
  padding: 1rem;
  border: none;
  color: #fff;
  background-color: rgb(45, 48, 71);
  border-radius: 0.8rem;
  cursor: pointer;
  transform: rotate(-90deg);
}

/* Media Query for screens with a maximum width of 1224px */
@media screen and (max-width: 1224px) {
  .Container-main .Container .User-details {
    text-align: center;
  }
  .Container-main .Container .Profile-Info {
    width: 78%;
  }
  .Container-main .Container .Profile-Navigation {
    width: 13%;
    left: 0rem
  }
  .Container-main .Container .User-details span {
    font-size: 1.7rem;
  }
  .Container-main .Container .User-details h2 {
    font-size: 1.5rem;
  }
}
/* Media Query for screens with a maximum width of 910px */
@media screen and (max-width: 998px) {
  .Container-main .Container .Profile-Info {
    width: 74%;
  }
  .Container-main .Container .Profile-Navigation {
    width: 15%;
    left: 0rem
  }
  .Container-main .Container .User-details span {
    font-size: 1.4rem;
  }
  .Container-main .Container .User-details h2 {
    font-size: 1.2rem;
  }
}

/* Media Query for screens with a maximum width of 768px */
@media screen and (max-width: 788px) {
  .Container {
    flex-direction: column;
    top: 15vh;
  }

  .Container-main .Container .Profile-Navigation {
    display: none;
    content: '';
  }

  .Container-main .Container .Profile-Info {
    width: 100%;
    position: static;
    margin-bottom: 2rem;
  }

  .Container-main .Container .Menu li {
    width: 100%;
    border-bottom: none;
    height: auto;
  }

  .Container-main .Container .Menu li a {
    justify-content: center;
    margin-left: 0;
  }

  .Section {
    border-radius: 0;
    position: relative;
  }

  .Logo-form {
    font-weight: 400;
    font-size: medium;
    padding: 0.8rem;
    width: fit-content;
  }
}

/* Media Query for screens with a maximum width of 576px */
@media (max-width: 576px) {
  .Container {
    top: 4vh;
  }
  .Container-main .Container .Profile-Navigation {
    display: none;
  }
  .Container-main .Container .Profile-Info {
        margin-top: 7rem !important;
  }
  .Container-main .Container .Profile {
    padding: 0.5rem;
  }

  .Container-main .Container .User-details span {
    font-size: 1.8rem;
  }

  .Container-main .Container .User-details h2 {
    font-size: 1.4rem;
  }

  .Container-main .Container .Menu li a {
    font-size: 1.4rem;
  }

  .Container-main .Container p {
    font-size: 1.2rem;
  }
  .Headers {
        font-size: 1.1rem;
        transform: scale(1);
        padding: 1rem;
        align-self: center;
        text-align: start;
  }
}
