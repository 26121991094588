.Skills {
  width: 95%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Contents__menu {
  width: 100%;
  height: 5vh;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.Contents__menu .menu__link {
  border: none;
  background-color: transparent;
  color: #343a40;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.Contents__menu .menu__link.active {
  font-weight: 500;
  border-bottom: 2px solid #343a40;

}

.Contents__menu .menu__link:hover {
  cursor: pointer;
  color: #343a40;
  font-weight: 500;
  border-bottom: 2px solid #343a40;
}

.list {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-direction: row;
}

.list li {
  display: flex;
  align-items: center;
  justify-self: center;
  font-size: 2rem;
  gap: 1rem;
}

/* Media Query for screens with a maximum width of 768px */
@media (max-width: 768px) {
  .Skills {
    justify-content: flex-start;
  }
  .list {
    flex-wrap: wrap;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 1.4rem;
    width: 16.4rem;
    width: 100%;
    margin-bottom: 1rem;
  }
  .list li {
    width: 100%;
    height: 1.6rem;
    border-bottom: 1px solid rgba(50, 50, 93, 0.25);
    display: flex;
    font-size: 1.6rem;
    font-weight: lighter;
    justify-content: flex-start;
    align-items: center;
    padding: 0.4rem;
    gap: 1.4rem;
  }
  .list li:hover {
    cursor: pointer;
    background-color: #343a40;
    color: #fff;
  }
}

/* Media Query for screens with a maximum width of 576px */
@media (max-width: 620px) {
  .list li {
    font-size: 1.4rem;
  }
  .Contents__menu {
    gap: 0.4rem;
  }
  .Contents__menu .menu__link {
    font-size: 1.4rem;
    padding: 0.5rem;
  }
}

@media (max-width: 356px) {
  .Contents__menu .menu__link {
    font-size: 1.2rem;
    padding: 0.3rem;
  }
}

@media (max-width: 299px) {
  .Contents__menu .menu__link {
    font-size: 1.05rem;
    padding: 0.6rem;
  }
}

@media (max-width: 277px) {
  .Contents__menu .menu__link {
    font-size: 1rem;
    padding: 0.6rem;
  }
}