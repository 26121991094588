.Resumes {
  width: 100%;
  padding: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.Resumes-header {
  width: 100%;
  justify-content: flex-start;
  border-bottom: 1px solid;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.Resumes-header span,
.Resumes-header2 span {
  width: 100%;
  font-size: 1.2rem;
}
.Resumes-obj {
  width: 95%;
  margin-bottom: 4rem;
}

.Resumes-obj li {
  font-size: 1.6rem;
  font-weight: 300;
}

.Resumes-header2 {
  width: 100%;
}

.logos{
  width: 10rem;
  height: 3rem;
  margin-right: -2rem;
  border-radius: 12%;
}

/* Media Query for screens with a maximum width of 830px */
@media (max-width: 830px) {
  .Resumes {
    width: 100%;
  }
  .Resumes:nth-child(4) > .Resumes-obj {
    margin-bottom: 1rem;
  }

  .Resumes-header {
    justify-content: space-around;
    flex-wrap: inherit;
    gap: initial;
    width: 100%
  }

  .Resumes-header span,
  .Resumes-header2 span {
    font-size: 1rem;
    /*padding: 1rem 1.5rem;*/
    flex-shrink: 1;
  }
  .Resumes-header2 span {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 2;
  }
  .Resumes-header span {
    display: flex;
    justify-content: flex-end;
  }

  .logos {
    display: none;
  }

  .Resumes-obj {
    width: 100%;
  }

  .Resumes-obj li {
    font-size: 1.2rem;
    padding-left: 1.8rem;
  }

  .Resumes-header2 {
    justify-content: center;
  }
}

@media screen and (min-width: 764px) {
  .Resumes-header span,
  .Resumes-header2 span {
    justify-content: flex-end;
    display: flex;
    margin-right: 1rem;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 723px) {
  .Resumes:nth-child(4) > .Resumes-header2 {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .Resumes:nth-child(4) > .Resumes-header2  > span {
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
  }
}

/* Media Query for screens with a maximum width of 576px */
@media (max-width: 576px) {
  .Resumes-obj li {
    font-size: 1.2rem;
    padding-left: 1rem;
  }
  .Resumes-header {
    border-bottom: none;
    background-color: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }
  .Resumes-header2 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .Resumes-header .Headers {
    font-size: 1.6rem;
  }
  .Resumes-header2 .Headers {
    margin-top: -2rem;
  }
  .Resumes-header span {
    display: flex;
    justify-content: flex-start;
    color: #2d3047;
    font-weight: 600;
    font-size: 1rem;
    width: 95%;
  }
  .Resumes-header2  > span {
    position: relative;
    justify-content: flex-start;
    color: #2d3047;
    font-weight: 600;
    font-size: 1rem;
    margin-top: -2rem;
    margin-bottom: 2rem;
    width: 95%;
  }
}

/* Media Query for screens with a maximum width of 370px */
@media (max-width: 483px) {
  .Headers {
    text-align: left;
  }
   .Resumes-header2 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    /*margin-top: -4rem;*/
  }
  .Resumes-header2  > .Headers{
    font-size: 1rem;
  }
  .Resumes-header2  > .Headers,
  .Resumes-header2  > span  {
    position: relative;
    justify-content: flex-start;
    z-index: none;
    font-weight: 600;
  }
}

@media (max-width: 323px) {
  .Resumes-header .Headers {
    font-size: 1.4rem;
  }
  .Resumes-header2 > span {
    /* left: -1.2rem; */
    margin-bottom: 1rem
  }
  .Headers {
    margin-top: 2rem;
  }
}
